import React from 'react'

import TiktokIcons from '@/components/atoms/Icon/sns/TiktokIcons'
import InstagramIcons from '@/components/atoms/Icon/sns/InstagramIcons'
import TwitterIcons from '@/components/atoms/Icon/sns/TwitterIcons'
import YoutubeIcons from '@/components/atoms/Icon/sns/YoutubeIcons'
import FacebookIcons from '@/components/atoms/Icon/sns/FacebookIcons'
import ClubhouseIcons from '@/components/atoms/Icon/sns/ClubhouseIcons'
import LineIcons from '@/components/atoms/Icon/sns/LineIcons'
import { useThemeContext } from '@/contexts/ThemeContext'

interface Props {
  type: string
  isOriginal: boolean
  isWHIcon?: boolean
}

const SnsLinkButton = ({ type, isOriginal, isWHIcon }: Props) => {
  const { isDark } = useThemeContext()
  const iconTypeOfTheme = isDark ? 'wh' : 'bk'
  const defaultWidth = '24px'
  const defaultHeight = '24px'
  const height = isOriginal ? '32px' : defaultHeight
  const width = isOriginal ? '32px' : defaultWidth
  const style = {
    width,
    height,
  }
  const iconType = isOriginal ? 'r_clr' : isWHIcon ? 'wh' : iconTypeOfTheme

  switch (type) {
    case 'clubhouse':
      return <ClubhouseIcons type={iconType} width={height} height={height} style={style} />
    case 'facebook':
      return <FacebookIcons type={iconType} width={height} height={height} style={style} />
    case 'instagram':
      return <InstagramIcons type={iconType} width={width} height={height} style={style} />
    case 'line':
      return <LineIcons type={iconType} width={width} height={height} style={style} />
    case 'tiktok':
      return <TiktokIcons type={iconType} width={width} height={height} style={style} />
    case 'twitter':
      return <TwitterIcons type={iconType} width={width} height={height} style={style} />
    case 'youtube':
      return <YoutubeIcons type={iconType} width={width} height={height} style={style} />
    default:
      return <></>
  }
}

export default SnsLinkButton
