import React, { CSSProperties } from 'react'
import ShortsIcon from '@/assets/img/icons/sns/Shorts.svg'

type IconProps = {
  width?: string
  height?: string
  onClick?: (e: any) => void
  style?: CSSProperties
}

const _ShortsIcons = ({ width, height }: IconProps) => {
  return (
    <ShortsIcon
      style={{
        width: width ? width : '24px',
        height: height ? height : '24px',
      }}
    />
  )
}

const ShortsIcons = (props: IconProps) => {
  return (
    <div onClick={props.onClick} style={props.style}>
      <_ShortsIcons {...props} />
    </div>
  )
}

export default ShortsIcons
