import React, { useEffect, useState } from 'react'
import getConfig from 'next/config'
import VideoLine from '@/assets/img/icons/VideoLine.svg'
import { Colors } from '@/constants/styles/color'
import { YoutubePlayer } from '@/components/atoms/SocialMediaEmbed/YoutubePlayer'
import { ShortsPlayer } from '@/components/atoms/SocialMediaEmbed/ShortsPlayer'
import { TikTokPlayer } from './TikTokPlayer'

type Props = {
  id: number
  type: 'none' | 'youtube' | 'tiktok' | 'shorts' // 他に使用するSNSが増えたら追加
  url: string
  width?: string
  height?: string
}

const SocialMediaEmbed = ({ id, type, url, width, height }: Props) => {
  const { publicRuntimeConfig } = getConfig()
  const [linkUrl, setLinkUrl] = useState<string>('')

  /**
   * TikTok 短縮URLから正式URLを取得し
   * 返却されたciteをセットする
   */
  const setReplacedTikTokLink = async (url: string) => {
    try {
      const res = await fetch(`${publicRuntimeConfig.API_URL}/api2/parse_tiktok_url?url=${url}`)
      const data = await res.json()
      setLinkUrl(data?.cite || '')
    } catch (e) {
      /* TODO エラーハンドリング
       * 共通: 開発環境ではコンソールにエラーを出力する
       * 1. 404: リンクが無効
       * 2. 500: サーバーエラー
       * 3. その他: 通信エラー
       */
    }
  }

  const blankMedia = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '176px',
          background: Colors.BK_GRAY,
          borderRadius: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 8px',
        }}
      >
        <VideoLine />
        <VideoLine />
      </div>
    )
  }

  useEffect(() => {
    if (url) {
      // YouTube短縮URLだった場合、URLを置換
      if (url.includes('https://youtu.be/', 0)) {
        const videoId = url.replace('https://youtu.be/', '')
        const replacedLink = `https://www.youtube.com/watch?v=${videoId}`
        setLinkUrl(replacedLink)
        return
      }

      // TikTok短縮URLだった場合、URLを置換
      if (url.includes('https://vt.tiktok.com/', 0)) {
        // TikTokURL変更時に画面が更新されなかったため、初期化する
        setLinkUrl('')
        setReplacedTikTokLink(url)
        return
      }

      if (linkUrl !== '' && type === 'tiktok') {
        // TikTokURL変更時に画面が更新されなかったため、初期化する
        setLinkUrl('')
        setTimeout(() => {
          setLinkUrl(url)
        }, 1000)
        return
      }

      if (url.startsWith('https://youtube.com/shorts/') && type === 'shorts') {
        const videoId = url.replace('https://youtube.com/shorts/', '')
        const replacedLink = `https://youtube.com/embed/${videoId}`
        setLinkUrl(replacedLink)
        return
      }

      setLinkUrl(url)
    }
  }, [url, type])

  if (linkUrl === '') {
    return blankMedia()
  }

  switch (type) {
    case 'youtube':
      return <>{linkUrl && <YoutubePlayer id={id} url={linkUrl} />}</>
    case 'shorts':
      return <>{linkUrl && <ShortsPlayer id={id} url={linkUrl} />}</>
    case 'tiktok':
      return <>{linkUrl && <TikTokPlayer id={id} url={linkUrl} />}</>
    default:
      return blankMedia()
  }
}

export default SocialMediaEmbed
