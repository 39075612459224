import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Colors } from '@/constants/styles/color'
import { getThemeBgColor, getThemeTextColor } from '@/contexts/ThemeContext'
import { THEMES } from '@/types/Creator.type'
import { FormState } from '../..'

const contentMixin = (props: ContentMixin) => {
  return css`
    border-radius: ${props.borderRadius};
    box-shadow: ${props.boxShadow};
    content: ${props.content};
    outline-offset: ${props.outlineOffset};
    outline: ${props.outline};
    background: ${props.background};
  `
}

export const StyledHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.VERY_LIGHT_GRAY};
  align-items: center;
  font-size: 11px;
  background-color: ${Colors.PRIMARY_GRAY};
  height: 56px;
  padding: 0px 12px 0px 16px;
`

export const StyledDialogHeader = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.WHITE};
`
export const StyleCancelButton = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${p => (p.color ? p.color : Colors.WHITE)};
  cursor: pointer;
  &:hover {
    color: ${Colors.VERY_LIGHT_GRAY};
  }
`
export const StyledHeaderTitle = styled.p`
  font-size: 14px;
  letter-spacing: 0.25px;
  margin: 0;
`
export const StyleSaveButton = styled.div<{
  isChecked: boolean
}>`
  display: flex;
  justify-content: center;
  width: 64px;
  height: 32px;
  border-radius: 16px;
  background-color: ${p => (p.isChecked ? Colors.FANME_YELLOW : Colors.GRAY)};
  color: ${p => (p.isChecked ? Colors.PRIMARY_GRAY : Colors.LIGHT_GRAY)};
  align-items: center;
  cursor: pointer;
`
export const StyledPreviewText = styled.p`
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  margin: 16px 24px;
`
export const StyledText = styled.p<{
  color?: string
  textAlign?: string
  content?: string
}>`
  margin: 0px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.25px;
  color: ${p => (p.color ? p.color : Colors.PRIMARY_GRAY)};
  text-align: ${p => (p.textAlign ? p.textAlign : 'center')};
  height: 24px;
  border-left: 1px solid ${Colors.PRIMARY_GRAY};
  padding-left: 12px;
`

export const StyledHeadLineText = styled.p<{ margin?: string }>`
  margin: ${props => props.margin || '0 24px'};
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0.25px;
  color: ${p => (p.color ? p.color : Colors.PRIMARY_GRAY)};
  height: 24px;
  border-left: 1px solid ${Colors.PRIMARY_GRAY};
  padding-left: 12px;
  position: relative;
`

export const StyleSelectedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: ${({ theme }) => getThemeTextColor(theme as THEMES)};
  background-color: ${({ theme }) =>
    typeof theme === 'string' ? getThemeBgColor(theme as THEMES) : 'initial'}};
`

export const StyleProgressWrapper = styled.div`
  width: 100%;
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
export const StyleProgress = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.VERY_LIGHT_GRAY};
`
export const StyleProgressText = styled.p<{
  isActive?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 1.2px;
  color: ${p => (p.isActive ? Colors.WHITE : Colors.GRAY)};
  background-color: ${p => (p.isActive ? Colors.PRIMARY_GRAY : Colors.VERY_LIGHT_GRAY)};
  &.style {
    -webkit-clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 0% 0%);
    clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 0% 0%);
  }
  &.color {
    -webkit-clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
    clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
  }
  &.text {
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
    clip-path: polygon(100% 0, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
  }
`

export const StyledContentContainer = styled.div`
  min-height: 344px;
  padding: 24px 0 0;
`

export const StyleContentStyle = styled.div`
  margin: 16px 24px 40px;
`
export const StylePreviewContent = styled.div<{ width?: string }>`
  display: flex;
  width: ${p => (p.width ? p.width : '100%')};
  background-color: ${({ theme }) =>
    typeof theme === 'string' ? getThemeBgColor(theme as THEMES) : 'initial'}};
`
export const StyleContents = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  max-width: 375px;
  margin: auto;
`
// サンプル
export const StyleContent = styled.div<CombinedProps>`
  width: calc((100% - 24px) / 2);
  max-width: 152px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: ${p => (p.border ? p.border : '')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${Colors.WHITE};
  border-radius: ${p => p.borderRadius ?? ''};
  border-color: ${p => p.borderColor ?? ''};
  box-shadow: ${p => p.boxShadow ?? ''};
  background-color: ${p => p.backgroundColor ?? ''};
  background-image: ${p => p.backgroundImage ?? ''};

  ${props => contentMixin(props)}
  //吹き出し最初の2つ
  &:nth-of-type(5),
  &:nth-of-type(6) {
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
    }
    &:before {
      bottom: -15px;
      margin-left: -12px;
      border: 11px solid transparent;
      border-top: 9px solid ${Colors.WHITE};
      z-index: 2;
    }
    &:after {
      bottom: -11px;
      margin-left: -7px;
      border: 6px solid transparent;
      border-top: 5px solid ${Colors.PRIMARY_GRAY};
      z-index: 1;
    }
  }

  ${p => {
    // 吹き出しの矢印(preview)
    const { isArrow, arrowAfter, arrowBefore } = p
    return (
      isArrow &&
      `
    &[id='5'],
    &[id='6'] {
      position: relative;
      &:before,&:after {
        content: '';
        position: absolute;
        left: 50%;
      }
      &:before {
        bottom: -15px;
        margin-left: -12px;
        border: 11px solid transparent;
        border-top: ${arrowBefore ? `9px solid ${arrowBefore}` : `9px solid ${Colors.WHITE}`};
        z-index: 2;
      }
      &:after {
        bottom: -11px;
        margin-left: -7px;
        border: 6px solid transparent;
        border-top: ${arrowAfter ? `5px solid ${arrowAfter}` : `5px solid ${Colors.PRIMARY_GRAY}`};
        z-index: 1;
      }
    }
    `
    )
  }}
`

export const StyleContentSelected = styled.div`
  width: 168px;
  height: 60px;
  border-radius: 8px;
  border: 3px solid ${Colors.FANME_YELLOW};
  position: absolute;
`

export const StyleContentText = styled.div<{ color?: string }>`
  color: ${p => p.color || Colors.PRIMARY_GRAY};
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
`

type ContentMixin = {
  outline?: string
  outlineOffset?: string
  border?: string
} & Partial<FormState>
//吹き出し
type ArrowProps = {
  isArrow?: boolean
  arrowAfter?: string
  arrowBefore?: string
}
type PreviewProps = {
  borderRadius?: string
  borderColor?: string
  boxShadow?: string
  backgroundColor?: string
  backgroundImage?: string
  width?: string
}
type CombinedProps = ContentMixin & ArrowProps & PreviewProps

export const StyleContentItem = styled.div<CombinedProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => (p.width ? p.width : '40%')};
  height: 44px;
  border: ${p => (p.border ? p.border : '')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border-radius: ${p => p.borderRadius ?? ''};
  border-color: ${p => p.borderColor ?? ''};
  box-shadow: ${p => p.boxShadow ?? ''};
  background-color: ${p => p.backgroundColor ?? ''};
  background-image: ${p => p.backgroundImage ?? ''};
  margin: 0 24px 32px;

  ${props => contentMixin(props)}
  //吹き出し最初の2つ
  &:nth-of-type(5),
  &:nth-of-type(6) {
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
    }
    &:before {
      bottom: -15px;
      margin-left: -12px;
      border: 11px solid transparent;
      border-top: 9px solid ${Colors.WHITE};
      z-index: 2;
    }
    &:after {
      bottom: -11px;
      margin-left: -7px;
      border: 6px solid transparent;
      border-top: 5px solid ${Colors.PRIMARY_GRAY};
      z-index: 1;
    }
  }

  ${p => {
    // 吹き出しの矢印(preview)
    const { isArrow, arrowAfter, arrowBefore } = p
    return (
      isArrow &&
      `
    &[id='5'],
    &[id='6'] {
      position: relative;
      &:before,&:after {
        content: '';
        position: absolute;
        left: 50%;
      }
      &:before {
        bottom: -15px;
        margin-left: -12px;
        border: 11px solid transparent;
        border-top: ${arrowBefore ? `9px solid ${arrowBefore}` : `9px solid ${Colors.WHITE}`};
        z-index: 2;
      }
      &:after {
        bottom: -11px;
        margin-left: -7px;
        border: 6px solid transparent;
        border-top: ${arrowAfter ? `5px solid ${arrowAfter}` : `5px solid ${Colors.PRIMARY_GRAY}`};
        z-index: 1;
      }
    }
    `
    )
  }}
`
export const StyleColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 24px;
`
export const StyleBorderLine = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 24px 24px;
`
export const StyleBackgroundColor = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 24px 24px;
`
export const StyleBackgroundWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`
export const StyleShadowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 130px;
`
export const Swatch = styled.div<{ backgroundColor?: string }>`
  width: 48px;
  height: 48px;
  border-radius: 16px;
  display: inline-block;
  cursor: pointer;
  background-color: ${p => p.backgroundColor};
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
`

export const Popover = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 1000;
  touch-action: none;
`

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1000;
  background: ${Colors.PRIMARY_GRAY};
  opacity: 0.5;
  max-width: 480px;
  margin: auto;
`
export const CoverButton = styled.div`
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${Colors.WHITE};
  right: 0px;
  top: -40px;
  cursor: pointer;
  svg path {
    fill: ${Colors.PRIMARY_GRAY};
  }
`
export const CoverButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const CoverDoneButton = styled.div<{ disable?: boolean }>`
  position: absolute;
  width: 200px;
  height: 40px;
  border-radius: 20px;
  margin-top: 20px;
  background-color: ${p => (p.disable ? Colors.GRAY : Colors.FANME_YELLOW)};
  cursor: pointer;
`
export const CoverDoneButtonText = styled.p`
  color: ${Colors.PRIMARY_GRAY};
  margin: 8px auto;
`
export const StyleBgImgWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const StyleBgImgItem = styled.div<{ url?: string; borderRadius?: string }>`
  position: relative;
  height: 48px;
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: ${p => p.borderRadius};
  margin: 0 24px 24px;
`
export const StyleBgImgCancelBtn = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${Colors.PRIMARY_GRAY};
  right: -4px;
  top: -12px;
  cursor: pointer;
  svg path {
    fill: ${Colors.WHITE};
  }
`
export const StyleSwitchBtnWrapper = styled.div`
  padding: 10px 0 10px 12px;
  cursor: pointer;
`
export const StyleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
`
export const StyleSwitchWrapperImg = styled.div`
  position: absolute;
  cursor: pointer;
  position: absolute;
  cursor: pointer;
  top: 0;
  bottom: 0;
  left: 12px;
  margin: auto;
  display: flex;
  align-items: center;
`

export const StyleBgImgUploadBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  background-color: ${Colors.WHITE};
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.08));
  margin: 0 auto 40px;
  width: 200px;
  height: 40px;
  border-radius: 20px;
`
export const StyleBgImgUploadBtnText = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
`

export const StyleButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin: 0 24px;
`

export const StyleNextButton = styled.button<{
  disabled: boolean
  width?: string
}>`
  width: ${p => p.width || 'calc(100% - 48px)'};
  min-width: ${p => p.width || 'calc(100% - 48px)'};
  height: 48px;
  color: ${Colors.WHITE};
  background-color: ${p => (p.disabled ? Colors.VERY_LIGHT_GRAY : Colors.PRIMARY_GRAY)};
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  border-radius: 24px;
  border: none;
  margin: 0;
`

export const StylePreviousButton = styled.button<{ width?: string }>`
  width: ${p => p.width || '100%'};
  max-width: 152px;
  height: 48px;
  color: ${Colors.PRIMARY_GRAY};
  background-color: ${Colors.WHITE};
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  border-radius: 24px;
`
