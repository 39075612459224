/* eslint-disable @next/next/no-img-element */
import React, { useCallback, useState } from 'react'

import YouTube from 'react-youtube'
import styled from '@emotion/styled'
import YoutubeIcons from '@/components/atoms/Icon/sns/YoutubeIcons'
import { event } from '@/pages/api/gtag'

const StyledPlayerWrapper = styled.div`
  min-height: 176px;
`

const Placeholder = styled.div`
  position: relative;
`

const IconWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
`

export type YoutubePlayerProps = {
  id: number
  url: string
}

const imageUrl = (url: string) => {
  const videoId = getVideoId(url)
  return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
}

export const getVideoId = (url: string): string => {
  const urlObj = new URL(url)
  const path = urlObj.pathname.split('/')
  let ret = ''
  if (path[1] === 'embed') ret = path[2]
  else if (path[1] === 'watch') ret = urlObj.searchParams.get('v') || ''
  else ret = path[1]

  return ret.split('?').shift() || ''
}

export function YoutubePlayer({ id, url }: YoutubePlayerProps) {
  const [playing, setPlaying] = useState<boolean>(false)
  const play = useCallback(() => setPlaying(true), [])
  const sendGtag = (id: number) => {
    event({
      action: 'click',
      category: 'creator_page_click',
      label: 'player',
      value: id,
    })
  }

  const videoOptions = {
    height: '176',
    width: '300',
    playerVars: {
      autoplay: 1,
      mute: 1,
    },
  }

  const onPlayerReady = (event: { target: any }) => {
    const player = event.target
    player.playVideo()
  }

  return (
    <StyledPlayerWrapper data-testid="youtube">
      {!playing ? (
        <Placeholder
          onClick={() => {
            play()
            sendGtag(id)
          }}
        >
          <img src={imageUrl(url)} alt={url} width={300} height={176} loading={'eager'} />
          <IconWrap>
            <YoutubeIcons type="ply" width={'68px'} height={'48px'} />
          </IconWrap>
        </Placeholder>
      ) : (
        <div id={`player-${url}`}>
          <YouTube opts={videoOptions} videoId={getVideoId(url) || ''} onReady={onPlayerReady} />
        </div>
      )}
    </StyledPlayerWrapper>
  )
}
