import React, { useEffect, useState } from 'react'
import { Link } from '@nextui-org/react'
import styled from '@emotion/styled'
import SnsLinkButton from '@/components/molecules/SnsLinkButton'

import { LinkCategory } from '@/types/Creator.type'
import { event } from '@/pages/api/gtag'
import { useThemeContext } from '@/contexts/ThemeContext'

interface Props {
  snsLinks: LinkCategory[]
  snsColor: string
  margin?: string
  isWHIcon?: boolean
}

const StyledContainer = styled.div<{ iconGap: string }>`
  display: flex;
  min-width: 100%;
  margin: 16px auto;
  width: 100%;
  padding: 0;
  flex-wrap: nowrap;
  justify-content: center;
  column-gap: ${({ iconGap }) => iconGap};

  @media (max-width: 440px) {
    column-gap: ${({ iconGap }) => `calc(${iconGap} * 0.8)`};
  }
  @media (max-width: 400px) {
    column-gap: ${({ iconGap }) => `calc(${iconGap} * 0.7)`};
  }
  @media (max-width: 360px) {
    column-gap: ${({ iconGap }) => `calc(${iconGap} * 0.6)`};
  }
  @media (max-width: 320px) {
    column-gap: ${({ iconGap }) => `calc(${iconGap} * 0.5)`};
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SnsLinkList = ({ snsLinks, snsColor, margin, isWHIcon }: Props) => {
  const { themeType } = useThemeContext()
  const [iconGap, setIconGap] = useState('0px')

  useEffect(() => {
    let count = 0
    snsLinks.map(snsLink => {
      if (snsLink.displayable) {
        count++
      }
    })
    const isMono = isWHIcon ? true : snsColor === 'MONO'
    // SNSアイコン間のGAP設定
    switch (count) {
      case 1:
        setIconGap('0px')
        break
      case 2:
        setIconGap('72px')
        break
      case 3:
        setIconGap(isMono ? '64px' : '52px')
        break
      case 4:
        setIconGap(isMono ? '56px' : '46px')
        break
      case 5:
        setIconGap(isMono ? '48px' : '38px')
        break
      case 6:
        setIconGap(isMono ? '34px' : '24px')
        break
    }
  }, [snsLinks, snsColor, isWHIcon])

  const onClick = (id?: number) => {
    event({
      action: 'click',
      category: 'creator_page_click',
      label: 'sns',
      value: id,
    })
  }

  return (
    <StyledContainer iconGap={iconGap} style={{ margin: margin || '16px auto' }}>
      {snsLinks.map((snsLink, index) => {
        if (snsLink.displayable) {
          return (
            <Link
              href={snsLink.account_identity}
              key={`${snsLink.id}-${themeType}-${index}`}
              target="#"
              onClick={() => onClick(snsLink.id)}
            >
              <SnsLinkButton
                type={snsLink.type}
                isOriginal={snsColor === 'ORIG' ? true : false}
                isWHIcon={isWHIcon}
              />
            </Link>
          )
        }
      })}
    </StyledContainer>
  )
}

export default SnsLinkList
