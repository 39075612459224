/* eslint-disable @next/next/no-img-element */
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import styled from '@emotion/styled'
import axios from 'axios'
import useSWR from 'swr'
import { event } from '@/pages/api/gtag'
import TiktokIcons from '../Icon/sns/TiktokIcons'
import { OEmbedResponse } from './types'

const Player = styled.div`
  blockquote {
    margin: 0;
    padding: 0;
    color: inherit;
  }
`

const Placeholder = styled.div`
  position: relative;
`

const Thumbnail = styled.img`
  border-radius: 10px;
`

const IconWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
`

export type TikTokPlayerProps = {
  id: number
  url: string
}

const fetcher = async (url: string) => {
  const response = await axios.get(url)
  return response.data
}
const tiktokIframeHeight = 733

export function TikTokPlayer({ id, url }: TikTokPlayerProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [playing, setPlaying] = useState<boolean>(false)
  const [oEmbed, setOEmbed] = useState<OEmbedResponse>()
  const play = useCallback(() => setPlaying(true), [])
  const sendGtag = (id: number) => {
    event({
      action: 'click',
      category: 'creator_page_click',
      label: 'player',
      value: id,
    })
  }
  const { data: oEmbedData } = useSWR(`https://www.tiktok.com/oembed?url=${url}`, fetcher)
  const containerStyle = useMemo<CSSProperties | undefined>(
    () => ({
      minHeight: playing ? tiktokIframeHeight : undefined,
    }),
    [playing],
  )

  useEffect(() => {
    setOEmbed(oEmbedData)
  }, [oEmbedData])

  useEffect(() => {
    if (!playing || !oEmbed || !ref.current) {
      return
    }
    ref.current.innerHTML = ''
    ref.current.appendChild(document.createRange().createContextualFragment(oEmbed.html))
  }, [oEmbed, playing])

  if (!oEmbed) {
    return null
  }

  // Vercel 以外の Production 環境で next/image コンポーネントを使うとコンテナ内の node で画像変換処理するのが尋常ではなく遅くなり、
  // 状況によっては CPU やメモリが不足して 500 系エラーを投げてしまうため、
  // 画像処理サーバーが出来上がり、かつ s3 以外のリソースも変換できるようになるまで next/image コンポーネント使用不可
  return (
    <div style={containerStyle} data-testid="tiktok">
      <Player ref={ref} />
      {!playing && (
        <Placeholder
          onClick={() => {
            play()
            sendGtag(id)
          }}
        >
          <Thumbnail src={oEmbed.thumbnail_url} alt={oEmbed.title} />
          <IconWrap>
            <TiktokIcons type="sq_clr2" />
          </IconWrap>
        </Placeholder>
      )}
    </div>
  )
}
