/* eslint-disable @next/next/no-img-element */
import React, { useCallback, useState } from 'react'

import styled from '@emotion/styled'
import ShortsIcons from '@/components/atoms/Icon/sns/ShortsIcons'
import { getVideoId } from '@/components/atoms/SocialMediaEmbed/YoutubePlayer'
import { event } from '@/pages/api/gtag'

const StyledPlayerWrapper = styled.div`
  height: 585px;
  width: 329px;
`

const Placeholder = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const IconWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
`

export type ShortsPlayerProps = {
  id: number
  url: string
}

const imageUrl = (url: string) => {
  const videoId = getVideoId(url)
  return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`
}

const autoPlayUrl = (url: string) => {
  const urlObj = new URL(url)
  urlObj.searchParams.set('autoplay', '1')
  urlObj.searchParams.set('mute', '1')
  return urlObj.toString()
}

export function ShortsPlayer({ id, url }: ShortsPlayerProps) {
  const [playing, setPlaying] = useState<boolean>(false)
  const play = useCallback(() => setPlaying(true), [])
  const sendGtag = (id: number) => {
    event({
      action: 'click',
      category: 'creator_page_click',
      label: 'player',
      value: id,
    })
  }

  return (
    <StyledPlayerWrapper data-testid="youtube">
      {!playing ? (
        <Placeholder
          onClick={() => {
            play()
            sendGtag(id)
          }}
        >
          <img
            src={imageUrl(url)}
            alt={`Thumbnail for video ${url}`}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            loading="eager"
          />
          <IconWrap>
            <ShortsIcons width={'68px'} height={'90px'} />
          </IconWrap>
        </Placeholder>
      ) : (
        <iframe
          width="100%"
          style={{ aspectRatio: '9 / 16', border: 0 }}
          src={autoPlayUrl(url)}
          allow="accelerometer; autoplay; muted, clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </StyledPlayerWrapper>
  )
}
