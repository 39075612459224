import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Colors } from '@/constants/styles/color'

const commonStyles = css`
  text-align: center;
  overflow: hidden;
  margin: 0 auto;
  color: ${Colors.BLACK};
`

const StyledLink = styled.a`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: ${Colors.WHITE};
  padding: 8px;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
`

const StyledContentsItemContainer = styled.div<{ color?: string; justifyContent: string }>`
  position: relative;
  padding: 24px;
  margin: 24px 0;
  background: ${({ color }) => color || Colors.WHITE};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
`

const StyledContentsEditWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  padding: 0;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
`

const StyledButton = styled.button<{ isDark?: boolean }>`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isDark }) => (isDark ? Colors.WHITE : Colors.BLACK)};
  border-radius: 12px;
  height: 24px;
  width: 64px;
  min-width: 64px;
  margin: 0 8px;
  background-color: ${({ isDark }) => (isDark ? Colors.BLACK : Colors.WHITE)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const StyledContents = styled.div<{ inView: boolean }>`
  display: flex;
  width: 90%;
  margin: 0 auto;
  padding: 12px 0;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 24px;
  text-align: center;
  ${({ inView }) => fadeStyles({ inView })}
`

const StyledImage = styled.img<{ imgHeight: string }>`
  border-radius: 16px;
  width: 100%;
  height: ${({ imgHeight }) => imgHeight};
  object-fit: cover;
`

const StyledListContents = styled.div<{ inView: boolean }>`
  max-width: 328px;
  width: 90%;
  margin: 0 auto;
  padding: 12px 0;
  ${({ inView }) => fadeStyles({ inView })}
`

const fadeStyles = ({ inView }: { inView: boolean }) => `
  opacity: ${inView ? 1 : 0};
  transform: ${inView ? 'translateY(0px)' : 'translateY(50px)'};
  transition: opacity 1s, transform 0.5s;
`

const StyledContentText = styled.p<{ color: string; fontSize?: string }>`
  font-style: normal;
  font-weight: 500;
  max-width: 328px;
  margin: 8px auto 0;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  color: ${({ color }) => color || Colors.BLACK};
  font-size: ${({ fontSize }) => fontSize || '11px'};
`

const StyledTitleText = styled.p<{ color?: string }>`
  ${commonStyles};
  line-height: 13px;
  letter-spacing: 0em;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 0;
  color: ${({ color }) => color || Colors.BLACK};
`

const StyledDescriptionText = styled.p<{ color?: string }>`
  ${commonStyles};
  margin: 16px 0;
  line-height: 17px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  max-width: 304px;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color || Colors.BLACK};
`

export {
  StyledLink,
  StyledContainer,
  StyledContents,
  StyledImage,
  StyledListContents,
  StyledContentText,
  StyledContentsItemContainer,
  StyledContentsEditWrapper,
  StyledButton,
  StyledTitleText,
  StyledDescriptionText,
}
