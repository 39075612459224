import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@nextui-org/react'

import { useIsMounted } from '@/utils/hooks/is_mounted'
import { Colors } from '@/constants/styles/color'
import Home from '@/assets/img/icons/Home.svg'
import PageEdit from '@/assets/img/icons/PageEdit.svg'

import styles from './style.module.scss'

type Props = {
  creatorId: string
  isEdit?: boolean
}

const PageEditToggleButton = ({ creatorId, isEdit }: Props) => {
  const router = useRouter()
  const [editPageClicked, setEditPageClicked] = useState(false)
  const [creatorPageClicked, setCreatorPageClicked] = useState(false)
  const isMounted = useIsMounted()

  const onClickEditPage = async () => {
    setEditPageClicked(true)
    try {
      await router.push('/creator/contents')
    } finally {
      if (isMounted()) {
        setEditPageClicked(false)
      }
    }
  }

  const onClickCreatorPage = async () => {
    setCreatorPageClicked(true)
    try {
      await router.push(`/@${creatorId}`)
    } finally {
      if (isMounted()) {
        setCreatorPageClicked(false)
      }
    }
  }

  const Spinner = () => <div className={styles.spinner}></div>

  const buttonGroup = () => {
    return (
      <>
        <button
          className={styles.button}
          style={{
            color: isEdit ? Colors.WHITE : Colors.BLACK,
            background: isEdit ? Colors.TRANSPARENT : Colors.WHITE,
          }}
          onClick={onClickCreatorPage}
        >
          {creatorPageClicked ? (
            <Spinner />
          ) : (
            <Home fill={isEdit ? Colors.WHITE : Colors.BLACK} width={16} height={16} />
          )}
        </button>
        <button
          className={styles.button}
          style={{
            color: isEdit ? Colors.BLACK : Colors.WHITE,
            background: isEdit ? Colors.WHITE : Colors.TRANSPARENT,
          }}
          onClick={onClickEditPage}
        >
          {editPageClicked ? (
            <Spinner />
          ) : (
            <PageEdit fill={isEdit ? Colors.BLACK : Colors.WHITE} width={16} height={16} />
          )}
        </button>
      </>
    )
  }

  return (
    <div className={styles.ToggleButtons}>
      <div
        className={styles.base}
        style={{
          color: Colors.WHITE,
          background: Colors.TRANSPARENT_LIGHT_GRAY,
        }}
      >
        <Button.Group animated>
          <div className={styles.buttonGroup}>{buttonGroup()}</div>
        </Button.Group>
      </div>
    </div>
  )
}

export default PageEditToggleButton
