import React from 'react'
import { Container, Text } from '@nextui-org/react'

import { Colors } from '@/constants/styles/color'
import { useThemeContext } from '@/contexts/ThemeContext'

type Props = {
  size: 'sm' | 'sm_txt' | 'md' | 'md_txt'
  icon: React.ReactElement
  text?: string
  isRight: boolean
  onClick: () => void
  isBW?: boolean
}

const HalfRoundedButton = ({ size, icon, text, isRight, onClick, isBW }: Props) => {
  const { isDark } = useThemeContext()
  switch (size) {
    case 'sm':
      return (
        <div
          data-testid="half-rounded-button"
          style={{
            color: Colors.BLACK,
            background: Colors.WHITE,
            borderTopLeftRadius: isRight ? 0 : '20px',
            borderBottomLeftRadius: isRight ? 0 : '20px',
            borderTopRightRadius: isRight ? '20px' : 0,
            borderBottomRightRadius: isRight ? '20px' : 0,
            filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1))',
            width: '44px',
            height: '40px',
            padding: isRight ? '3px 3px 3px 7px' : '3px 7px 3px 3px',
            position: 'absolute',
            right: isRight ? 'auto' : 0,
            left: isRight ? 0 : 'auto',
          }}
          onClick={onClick}
        >
          <Container
            css={{
              dflex: 'center',
              fd: 'column',
              br: '50%',
              bg: Colors.VERY_LIGHT_GRAY,
              w: '34px',
              h: '34px',
              p: 0,
              m: 0,
            }}
          >
            {icon}
          </Container>
        </div>
      )
    case 'sm_txt':
      return (
        <div
          data-testid="half-rounded-button"
          style={{
            color: Colors.BLACK,
            background: Colors.WHITE,
            borderTopLeftRadius: isRight ? 0 : '20px',
            borderBottomLeftRadius: isRight ? 0 : '20px',
            borderTopRightRadius: isRight ? '20px' : 0,
            borderBottomRightRadius: isRight ? '20px' : 0,
            filter: 'drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1))',
            width: '87px',
            height: '40px',
            padding: isRight ? '3px 3px 3px 7px' : '3px 7px 3px 3px',
            position: 'absolute',
            right: isRight ? 'auto' : 0,
            left: isRight ? 0 : 'auto',
          }}
          onClick={onClick}
        >
          <Container
            css={{
              dflex: 'center',
              fd: 'column',
              br: '50%',
              bg: Colors.VERY_LIGHT_GRAY,
              w: '34px',
              h: '34px',
              p: 0,
              m: 0,
            }}
          >
            {icon}
            <Text
              css={{
                color: Colors.BLACK,
                fs: '10px',
                lh: '10px',
                letterSpacing: '0.1em',
                fontFamily: 'Noto Sans JP',
                fontStyle: 'normal',
                fontWeight: '500',
                d: 'flex',
                ai: 'center',
                ta: 'center',
                ml: '6px !important',
              }}
            >
              {text}
            </Text>
          </Container>
        </div>
      )
    case 'md':
      return (
        <div
          data-testid="half-rounded-button"
          style={{
            color: Colors.BLACK,
            background: isDark ? Colors.WHITE : Colors.BLACK,
            border: isDark ? '' : `0.5px solid ${Colors.GRAY}`,
            borderTopLeftRadius: isRight ? 0 : '32px',
            borderBottomLeftRadius: isRight ? 0 : '32px',
            borderTopRightRadius: isRight ? '32px' : 0,
            borderBottomRightRadius: isRight ? '32px' : 0,
            filter: 'drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2))',
            minWidth: '68px',
            height: '64px',
            padding: '4px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={onClick}
        >
          <Container
            css={{
              dflex: 'center',
              fd: 'column',
              br: '50%',
              bg: isBW ? (isDark ? Colors.BLACK : Colors.WHITE) : Colors.FANME_YELLOW,
              w: '56px',
              h: '56px',
              p: 0,
            }}
          >
            {icon}
          </Container>
        </div>
      )
    case 'md_txt':
      return (
        <div
          data-testid="half-rounded-button"
          style={{
            color: Colors.BLACK,
            background: isDark ? Colors.WHITE : Colors.BLACK,
            border: isDark ? '' : `0.5px solid ${Colors.GRAY}`,
            borderTopLeftRadius: isRight ? 0 : '32px',
            borderBottomLeftRadius: isRight ? 0 : '32px',
            borderTopRightRadius: isRight ? '32px' : 0,
            borderBottomRightRadius: isRight ? '32px' : 0,
            filter: 'drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2))',
            minWidth: '68px',
            height: '64px',
            padding: '4px',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={onClick}
        >
          <Container
            css={{
              dflex: 'center',
              fd: 'column',
              br: '50%',
              bg: isBW ? (isDark ? Colors.BLACK : Colors.WHITE) : Colors.FANME_YELLOW,
              w: '56px',
              h: '56px',
              p: 0,
            }}
          >
            {icon}
            {text && (
              <Text
                css={{
                  color: isBW && isDark ? Colors.WHITE : Colors.BLACK,
                  fs: '10px',
                  lh: '10px',
                  letterSpacing: '0.1em',
                  mt: '5px !important',
                }}
              >
                {text}
              </Text>
            )}
          </Container>
        </div>
      )
  }
}

export default HalfRoundedButton
